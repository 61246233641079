import React from "react";
import '../../css/servicesection.css'
const ServiceSection = () => {
    const services = [
        { id: 1, title: "Same-Day Delivery", description: "Ideal for urgent, time-sensitive packages. We ensure your items reach their destination within hours for specific zones." },
        { id: 2, title: "Overnight Delivery", description: "Our overnight service guarantees next-day delivery, offering peace of mind for items that need to arrive quickly and securely." },
        { id: 3, title: "Domestic Parcel Service", description: "We deliver parcels of all sizes across Sri Lanka, with options for regular, expedited, and express delivery to meet your specific needs." },
        { id: 4, title: "E-commerce & Business Solutions", description: "Empower your business with tailored delivery options, including integration with your order management systems, to streamline operations." },
        { id: 5, title: "Cash on Delivery (COD)", description: "Providing COD options for e-commerce platforms, helping you reach customers who prefer cash payments." },
        { id: 6, title: "Return Management Services", description: "Efficiently handle returned packages with our easy-to-manage return services, designed for e-commerce and retail businesses." },
        { id: 7, title: "Bulk Shipments", description: "Special rates for high-volume or bulk shipments to optimize logistics costs for businesses with regular shipping needs." },
        { id: 8, title: "Document Courier Services", description: "Safely deliver sensitive and time-critical documents, with extra precautions taken for confidential items." },
    ];

    return (
        <div className="service-section">
            <div className="container">
                <h2 className="section-title">Our Services</h2>
                <p className="section-subtitle">At MDH Express, we offer a range of delivery options to meet the diverse needs of our clients.</p>
                <div className="service-list">
                    {services.map((service) => (
                        <div className="service-item" key={service.id}>
                            <h3>{service.title}</h3>
                            <p>{service.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServiceSection;
