import React from "react";
import ContactForm from "../ContactFrom/ContactForm";
import CallCenter from "../../images/call-center.webp";

const Contactpage = () => {
  return (
    <div>
      <section className="wpo-contact-page section-padding">
        <div className="container">
          <div className="office-info">
            <div className="row">
              <div className="col col-lg-4 col-md-6 col-12">
                <div className="office-info-item">
                  <div className="office-info-icon">
                    <div className="icon">
                      <i className="fi flaticon-location-1"></i>
                    </div>
                  </div>
                  <div className="office-info-text">
                    <h2>address line</h2>
                    <p> Bandarapola, Kivula, Matale, Sri Lanka </p>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 col-md-6 col-12">
                <div className="office-info-item active">
                  <div className="office-info-icon">
                    <div className="icon">
                      <i className="fi flaticon-phone-call"></i>
                    </div>
                  </div>
                  <div className="office-info-text">
                    <h2>Phone Number</h2>
                    <p>
                      <a
                        href="tel:0114203052"
                        style={{ color: "#687693", textDecoration: "none" }}
                      >
                        0114203052
                      </a>
                    </p>
                    <br />
                    <p>
                      <a
                        href="tel:0114203053"
                        style={{ color: "#687693", textDecoration: "none" }}
                      >
                        0114203053
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 col-md-6 col-12">
                <div className="office-info-item">
                  <div className="office-info-icon">
                    <div className="icon">
                      <i className="fi flaticon-email"></i>
                    </div>
                  </div>
                  <div className="office-info-text">
                    <h2>Email</h2>
                    <p>
                      <a
                        href="mailto:mdhexpress.mdh@gmail.com"
                        style={{ color: "#687693", textDecoration: "none" }}
                      >
                        mdhexpress.mdh@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contact-wrap">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="contact-left">
                  <h2>Get in Touch with MDH Express </h2>
                  <p>
                    Have questions about our services? Need assistance with a
                    delivery? We’re here to help!
                  </p>
                  <img
                    className="image"
                    src={CallCenter}
                    width="508px"
                    height="351px"
                    alt="call help"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="contact-right">
                  <div className="title">
                    <h2>Fill Up The Form</h2>
                    <p>
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                  </div>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contactpage;
