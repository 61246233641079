import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider1 from '../../images/slider/MDH-Delivery-banner-2.jpeg';
import Slider2 from '../../images/slider/MDH-Delivery-bvanner-2.jpeg';
import '../../css/popup.css';

const ClickHandler = () => {
    window.scrollTo(10, 0);
};

const HeroSlider = () => {
    const [orderId, setOrderId] = useState('');
    const navigate = useNavigate();

    const settings = {
        autoplay: true,
        speed: 800,
        lazyLoad: 'progressive',
        arrows: true,
        dots: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true,
                },
            },
        ],
    };

   

    

    const handleOrderIdSubmit = async () => {
        const url = `https://deliveryservicev2.dreamworld.lk/public/472/items/info?trackingIds=${orderId}`;

        try {
            const response = await fetch(url);

            if (response.ok) {
                const data = await response.json(); // Get order data
                navigate(`/tracking/${orderId}`, { state: { orderData: data[0] } });
            } else {
                toast.error('Order ID not found. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching order ID:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    return (
        <section className="hero-section">
            <Slider {...settings} className="hero-slider">
                <div>
                    <div className="slider-item">
                        <div className="bg-image">
                            <img className="animated" src={Slider1} alt="" data-animation-in="zoomInImage" />
                        </div>
                        <div className="container">
                            <div className="content">
                                <h3 className="animated" data-animation-in="fadeInUp">MDH Express  Your Trusted Delivery Partner in Sri Lanka</h3>
                                <p className="animated" data-animation-in="fadeInUp">
                                Welcome to MDH Express, Sri Lanka’s premier courier service for fast, reliable, and secure deliveries. With a
professional team and cutting-edge technology, MDH Express offers a seamless experience, making us the preferred
courier service in Sri Lanka.
                                </p>
                                <div className="hero-btn animated" data-animation-in="fadeInUp">
                                    <div className="btn-1">
                                        <Link onClick={ClickHandler} to="/services" className="theme-btn">
                                            All Services
                                        </Link>
                                    </div>
                                    <div className="btn-2">
                                        <a href="tel:0114203052" className="call">
                                            <div className="icon">
                                                <i className="flaticon-call"></i>
                                            </div>
                                            <div className="text">
                                                <span>Call Us Now</span>
                                                <h4>0114203052</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="slider-item">
                        <div className="bg-image">
                            <img className="animated" src={Slider2} alt="" data-animation-in="zoomInImage" />
                        </div>
                        <div className="container">
                            <div className="content">
                                <h3 className="animated" data-animation-in="fadeInUp">MDH Express  Your Trusted Delivery Partner in Sri Lanka</h3>
                                <p className="animated" data-animation-in="fadeInUp">
                                Welcome to MDH Express, Sri Lanka’s premier courier service for fast, reliable, and secure deliveries. With a
professional team and cutting-edge technology, MDH Express offers a seamless experience, making us the preferred
courier service in Sri Lanka.
                                </p>
                                <div className="hero-btn animated" data-animation-in="fadeInUp">
                                    <div className="btn-1">
                                        <Link onClick={ClickHandler} to="/services" className="theme-btn">
                                            All Services
                                        </Link>
                                    </div>
                                    <div className="btn-2">
                                        <a href="tel:0114203052" className="call">
                                            <div className="icon">
                                                <i className="flaticon-call"></i>
                                            </div>
                                            <div className="text">
                                                <span>Call Us Now</span>
                                                <h4>0114203052</h4>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
            <div className='track-btn' >
                <Popup
                    trigger={<button className="theme-btn">Track Order</button>}
                    modal
                    closeOnDocumentClick
                >
                    {(close) => (
                        <div className="popup-content">
                            <h4 className='text-id'>Enter Order ID</h4>
                            <input
                                type="text"
                                placeholder="Order ID"
                                value={orderId}
                                onChange={(e) => setOrderId(e.target.value)}
                                required
                            />
                            <button onClick={handleOrderIdSubmit} className="submit-btn" disabled={!orderId}>
                                Submit
                            </button>
                            <button onClick={close} className="close-btn">
                                Close
                            </button>
                        </div>
                    )}
                </Popup>
            </div>
        </section>
    );
};

export default HeroSlider;
