import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Error from '../../components/404/404'
import FooterS2 from '../../components/CustomFooter/FooterS2';
import Logo from '../../images/logo.png'


const ErrorPage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <Error/>
            <FooterS2 />
        </Fragment>
    )
};
export default ErrorPage;

