import React from 'react';
import '../../css/orderstatus.css';
import ArrowShape from '../../images/right-arrow.png';

const OrderStatusGrid = ({ order }) => {
    if (!order || !order.itemHistoryTracking) return <p>No order history available.</p>;

    // Sort the item history by date in ascending order (creating a new array to avoid mutation)
    const sortedHistory = [...order.itemHistoryTracking].sort(
        (a, b) => new Date(a.updatedDate) - new Date(b.updatedDate)
    );

    return (
        <div>
            <h2>Order ID: {order.orderId}</h2>
            <h3>Customer: {order.customerName}</h3>
            <h4>Current Status: {order.itemState}</h4>

            <div className="status-grid">
                {sortedHistory.map((status, index) => (
                    <div key={index} className="status-item-container">
                        <div className="status-item">
                            <p><strong>Status:</strong> {status.stateName}</p>
                            <p><strong>Updated Date:</strong> {new Date(status.updatedDate).toLocaleString()}</p>
                            {status.hub && <p><strong>Hub:</strong> {status.hub}</p>}
                            {status.remark && <p><strong>Remark:</strong> {status.remark}</p>}
                        </div>
                        {/* Display arrow only between items, not after the last one */}
                        {index < sortedHistory.length - 1 && (
                            <img src={ArrowShape} alt="arrow" className="status-arrow" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrderStatusGrid;
