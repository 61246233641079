import React, { useState } from "react";
import "../../css/SignupForm.css";
import Logo from "../../images/logo.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SignupForm = () => {
  const [formData, setFormData] = useState({
    businessName: "",
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  //const [errorMessage, setErrorMessage] = useState("");
  //const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Payload including accountId
    const signupData = {
      ...formData,
      accountId: "472", // Fixed account ID as per instructions
    };

    try {
      // Send data to the signup API
      const response = await fetch("https://userservicev2.dreamworld.lk/sign-up-request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signupData),
      });

      if (response.ok) {
        //setSuccessMessage("Registration successful!");
        toast.success('Registration successful!');
        setFormData({
          businessName: "",
          name: "",
          email: "",
          phoneNumber: "",
          address: "",
        });
        //setErrorMessage("");
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Registration failed. Please try again.");  
      }
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error('An error occurred during signup. Please try again.');
      setFormData({
        businessName: "",
        name: "",
        email: "",
        phoneNumber: "",
        address: "",
      });
      //setErrorMessage("An error occurred during signup. Please try again.");
      //setSuccessMessage("");
    }
  };

  return (
    <div className="signup-page">
      <div className="signup-form-container">
        <div className="logo-image">
          <img src={Logo} alt="logo" />
        </div>
        <h2>Sign Up</h2>
        <form onSubmit={handleSubmit} className="signup-form">
          <input
            type="text"
            name="businessName"
            placeholder="Business Name"
            value={formData.businessName}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
            required
          />
          <button type="submit" className="register-btn">
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignupForm;
