import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu/MobileMenu';
import { connect } from "react-redux";
import '../../css/authbutton.css'


const Header = (props) => {
    const [menuActive, setMenuState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault();
    };

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <header id="header">
            <div className={"" + props.hclass}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-3 d-lg-none">
                                <MobileMenu />
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/home">
                                        <img src={props.Logo} alt="logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 col-6">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li>
                                            <Link onClick={ClickHandler} to="/">Home</Link>
                                        </li>
                                        <li>
                                        <Link onClick={ClickHandler} to="/contact">Contact Us</Link> 
                                        </li> 
                                        <li>
                                        <Link onClick={ClickHandler} to="/services">Services</Link> 
                                        </li> 
                                        <li>
                                        <Link onClick={ClickHandler} to="/about">About Us</Link> 
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 d-flex justify-content-end">
                                <div className="auth-buttons">
                                    <a href="https://manager.mdhxpress.com/auth/login" className=" btn-login">Login</a>
                                    <Link to="/signup" className=" btn-signup">Sign Up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};

export default connect(mapStateToProps)(Header);
