import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import "../../css/FooterS2.css";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const FooterS2 = () => {
  return (
    <footer className="wpo-site-footer-s2">
      <div className="wpo-upper-footer-custom small-padding">
        <div className="container">
          <div className="row">
            <div>
              <div className="widget address-widget-custom">
                <p>
                  <strong>Head Office Address:</strong> Bandarapola, Kivula,
                  Matale, Sri Lanka
                </p>

                <p>
                  <strong>Phone:</strong>
                  <a
                    href="tel:0114203052"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    {" "}
                    0114203052
                  </a>{" "}
                  |
                  <a
                    href="tel:0114203053"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    {" "}
                    0114203053
                  </a>
                </p>

                <p>
                  <strong>Opening Hours:</strong> Monday – Saturday, 8:30 AM to
                  17:30 PM
                </p>

                <p>
                  <strong>Email:</strong>
                  <a
                    href="mailto:mdhexpress.mdh@gmail.com"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    mdhexpress.mdh@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer reduced-height">
        <div className="container">
          <div className="row g-0 align-items-center">
            <div className="col col-lg-2 col-12">
              <div className="logo">
                <a href="/">
                <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
            <div className="col col-lg-6 col-12">
              <p className="copyright">
                &copy; 2024{" "}
                <Link onClick={ClickHandler} to="/home">
                  MDH Express
                </Link>{" "}
                All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterS2;
