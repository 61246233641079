import React from 'react';
import Trolley from '../../images/trolley.jpg';

const About = (props) => {
    return (
        <section className={`${props.hclass || ''} about-section`} style={{ padding: '50px 0' }}>
            <div className="container">
                <div className="row align-items-center">
                    {/* Left Section */}
                    <div className="col-lg-6 col-12">
                        <div className="about-left text-center">
                            <img
                                src={Trolley}
                                width="100%"
                                height="auto"
                                alt="Parcel Delivery"
                                style={{ maxWidth: '400px', margin: '0 auto' }}
                            />
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="col-lg-6 col-12">
                        <div className="about-content">
                            <div className="title">
                                <h2 style={{ fontSize: '2rem', lineHeight: '1.5' }}>
                                    Experience the MDH difference – where every delivery matters
                                </h2>
                            </div>
                            <div className="text-about" style={{ marginTop: '20px' }}>
                                <ul style={{ listStyleType: 'none', padding: 0 }}>
                                    <li style={{ marginBottom: '15px' }}>
                                        <strong>Speed & Efficiency:</strong> We prioritize fast and dependable delivery for every package.
                                    </li>
                                    <li style={{ marginBottom: '15px' }}>
                                        <strong>Nationwide Coverage:</strong> Reach every corner of Sri Lanka with our extensive delivery network.
                                    </li>
                                    <li style={{ marginBottom: '15px' }}>
                                        <strong>Customer Support:</strong> Our friendly customer service team is here to assist you every step of the way.
                                    </li>
                                    <li style={{ marginBottom: '15px' }}>
                                        <strong>Affordable Pricing:</strong> Get premium courier services at competitive rates.
                                    </li>
                                    <li style={{ marginBottom: '15px' }}>
                                        <strong>Track Your Package:</strong> Stay updated on your shipment with real-time tracking.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
