import React from 'react';
import Ab22 from '../../images/delivery-man.avif';


const AboutS2 = () => {
    return (
        <section className="wpo-about-section-s2-custom">
            <div className="container">
                <div className="row align-items-center">
                    {/* Left Content Section */}
                    <div className="col-lg-6 col-12">
                        <div className="about-content">
                            <div className="wpo-section-title">
                                <h2>MDH Express</h2>
                                <h3>Redefining Transport & Logistics Services</h3>
                            </div>
                            <p>
                                MDH Express, is a forward-thinking courier company committed to setting new standards in delivery and logistics
                                across the nation. Driven by a mission to transform the industry, MDH Express upholds values of trust, reliability,
                                and efficiency in every service we provide.
                            </p>
                            <p>
                                Led by a team of experienced logistics professionals, MDH Express has developed an efficient approach to meet the
                                evolving needs of modern businesses and individuals. Since our inception, we have proudly served diverse sectors,
                                including corporate, e-commerce, retail, healthcare, and finance, treating each delivery with utmost care and
                                urgency.
                            </p>
                            <p>
                                Our operations are built on innovation, utilizing the latest technology for real-time tracking. With an extensive
                                network reaching every corner of Sri Lanka—from urban centers to rural areas—MDH Express bridges communities
                                and strengthens social and economic ties nationwide.
                            </p>
                        </div>
                    </div>

                    {/* Right Image Section */}
                    <div className="col-lg-6 col-12">
                        <div className="about-image">
                            <img src={Ab22} alt="MDH Express" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutS2;
