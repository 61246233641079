import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Homepage from '../HomePage/HomePage';
import AboutPage from '../AboutPage/AboutPage';
import TrackingPage from '../TrackingPage/TrackingPage';
import ServicePages from '../ServicePage/ServicePage';
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import SignupForm from '../../components/SignUp/SignUp';

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="/tracking/:trackingId" element={<TrackingPage />} />
          <Route path="services" element={<ServicePages />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="signup" element={<SignupForm />} />
          <Route path="404" element={<ErrorPage />} />

          {/* Redirect unmatched routes to the 404 page */}
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
