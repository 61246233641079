import React, { Fragment } from 'react';

import Navbar from '../../components/Navbar/Navbar'
import HeroSlider from '../../components/HeroSlider/HeroSlider';
import About from '../../components/about/about';
import Logo from '../../images/logo.png'
import Footer from '../../components/CustomFooter/FooterS2';

const HomePage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <HeroSlider />
            <About hclass={'wpo-about-section'} />
            <Footer />
        </Fragment>
    )
};
export default HomePage;