import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.png'
import FooterS2 from '../../components/CustomFooter/FooterS2';
const ServicePage = () => {
    
    return (
        <Fragment>
           
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <ServiceSection hclass={"wpo-service-section section-padding"}  />
            <FooterS2 />
            <Scrollbar />
        </Fragment>
    )
};
export default ServicePage;
