import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Contactpage from '../../components/Contactpage/Contactpage';
import FooterS2 from '../../components/CustomFooter/FooterS2';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Logo from '../../images/logo.png';


const ContactPage = () => {
    return (
        <Fragment>
           
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <Contactpage />
            
            <FooterS2 />
            <Scrollbar /> 
        </Fragment>
    )
};
export default ContactPage;





