import React, { Fragment } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import OrderStatusGrid from '../../components/OrderStatusGrid/OrderStatusGrid';
import Logo from '../../images/logo.png';
import Error from '../../components/404/404';
import FooterS2 from '../../components/CustomFooter/FooterS2'
const TrackingPage = () => {
    const { trackingId } = useParams(); // Access trackingId from URL
    const location = useLocation();
    const orderData = location.state?.orderData;

    if (!orderData || !trackingId) {
        return (
            <Error />
        );
    }

    return (
        <Fragment>
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <section className="track-page section-padding">
                <div className="container">
                    <div className="track-Shipment">
                        <h2>Order Tracking</h2>
                        <OrderStatusGrid order={orderData} />
                    </div>
                    <div className="order-detail">
                        <h3>Customer Details</h3>
                        <ul>
                            <li><span>Customer Name:</span> <span>{orderData.customerName}</span></li>
                            <li><span>Customer Address:</span> <span>{orderData.customerAddress}</span></li>
                            <li><span>Customer Phone Number:</span> <span>{orderData.customerPhoneNumber}</span></li>
                        </ul>
                    </div>
                    <div className="order-detail">
                        <h3>Order Details</h3>
                        <ul>
                            <li><span>Order Status:</span> <span>{orderData.itemState}</span></li>
                            <li><span>Total Order Amount:</span> <span>LKR {orderData.codAmount}</span></li>
                            <li><span>Payment Method:</span> <span>Cash on Delivery</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            <FooterS2/>
        </Fragment>
    );
};

export default TrackingPage;
