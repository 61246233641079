import React, { Fragment } from 'react';

import Navbar from '../../components/Navbar/Navbar'
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.png'
import FooterS2 from '../../components/CustomFooter/FooterS2';
import AboutS2 from '../../components/aboutS2/aboutS2';

const AboutPage = () => {
    return (
        <Fragment>
            {/* <HeaderTop /> */}
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <AboutS2/>
            <FooterS2 />
            <Scrollbar /> 
        </Fragment>
    )
};
export default AboutPage;
